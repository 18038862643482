import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faBullhorn, faPalette } from '@fortawesome/free-solid-svg-icons';
import './ServicesSection.css';

const ServicesSection = () => {
  return (
    <div className="services-section" id='services'>
      <h2 className="section-title">My Services</h2>
      <div className="services-container">
        
        {/* Service 1: e-Commerce Specialist */}
        <div className="service-card">
          <FontAwesomeIcon icon={faShoppingCart} className="service-icon" />
          <h3 className="service-title">e-Commerce Specialist</h3>
          <p className="service-content">
            As an e-Commerce Specialist, I will create and implement strategies & product listings that make online transactions possible. I will organize, develop, and execute website marketing plans. I am responsible for design & development of the website or portal to be well versed with networking, latest technology, programming, software development & SEO tactics to create a user-friendly & SEO-friendly website.
          </p>
        </div>

        {/* Service 2: Digital Marketing */}
        <div className="service-card">
          <FontAwesomeIcon icon={faBullhorn} className="service-icon" />
          <h3 className="service-title">Digital Marketing</h3>
          <p className="service-content">
            Planning and executing all web, SEO/SEM, database marketing, email, social media, and display advertising campaigns. Designing, building, and maintaining our client's social media presence. Measuring and reporting performance of all digital marketing campaigns and assessing against goals (ROI and KPIs). Building ideas, strategies, goals post analyzing the competitors and managing social media presence to increase sales, brand awareness & lead generation.
          </p>
        </div>

        {/* Service 3: Graphic Designing */}
        <div className="service-card">
          <FontAwesomeIcon icon={faPalette} className="service-icon" />
          <h3 className="service-title">Graphic Designing</h3>
          <p className="service-content">
            With the assistance of powerful images, colors, and layout, I can deliver strong messages to potential customers without using lengthy paragraphs. In simplest terms, graphics can make the message clearer and straightforward. Producing a host of ideas, selecting the best ones and selling them to clients. Encouraging others to share their ideas and nurturing creativity.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
