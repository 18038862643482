import React from "react";
import "./NextSection.css";
import aboutimg from "./assets/about me-img.jpeg";
const NextSection = () => {
  return (
    <div className="next-section" id="about">
      {/* Left Side: Vertical Oval-Shaped Man Image */}
      <div className="left-container">
        <div className="oval-image-container">
          <img src={aboutimg} alt="Man" className="oval-image" />
        </div>
      </div>

      {/* Right Side: Content */}
      <div className="right-container1">
        <div className="content">
          <h2>About Me</h2>
          <p>
            I design that help user more easily to use. An astute professional
            with over 7+ Years of experience in E-Commerce Portal, 2+ Years
            Experience in Graphic Designing &Digital Marketing (6 Months
            Experience). Motivated and accomplished e-commerce & digital
            marketing expert, attentive to details with outstanding
            follow-through. An innovative self-starter. I am proficient in
            building social media connections between clients and customers. I
            am well-versed in managing admin panels, product management,
            analysis, business strategies related to E-Commerce & Digital
            Marketing.
          </p>
          <button>
            <a href="/resume.pdf" download="Resume">
              Download Resume
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NextSection;
