import React from 'react'
import Navbar from './navbar'
import HeroSection from './HeroSection'
import NextSection from './NextSection'
import ServicesSection from './ServicesSection'
import Resume from './Resume'
import Portfolio from './Portfolio'
import ContactForm from './form'
import Footer from './Footer'

function App() {
  return (
   <>
   <Navbar/>
   <HeroSection/>
   <NextSection/>
   <ServicesSection/>
   <Resume/>
   <Portfolio/>
   <ContactForm/>
   <Footer/>
   </>
  )
}

export default App