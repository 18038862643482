import React, { useState } from 'react';
import './form.css'; // Add CSS if needed

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    let formErrors = {};

    // Name validation (not empty)
    if (!formData.name.trim()) {
      formErrors.name = 'Name is required';
    }

    // Phone validation (10-digit Indian phone number)
    const phoneRegex = /^[6-9]\d{9}$/;
    if (!formData.phone) {
      formErrors.phone = 'Phone number is required';
    } else if (!phoneRegex.test(formData.phone)) {
      formErrors.phone = 'Phone number must be a valid 10-digit Indian number';
    }

    // Email validation (standard format)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      formErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      formErrors.email = 'Invalid email format';
    }

    // Message validation (not empty)
    if (!formData.message.trim()) {
      formErrors.message = 'Message is required';
    }

    return formErrors;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErrors = validate();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const { name, phone, email, message } = formData;

    // Construct the Gmail URL with prefilled fields
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=sparkersubbu2011@gmail.com&su=Contact from ${encodeURIComponent(
      name
    )}&body=Phone: ${encodeURIComponent(phone)}%0D%0AEmail: ${encodeURIComponent(
      email
    )}%0D%0A%0D%0A${encodeURIComponent(message)}`;

    // Open Gmail in a new tab with the prefilled details
    window.open(gmailLink, '_blank');

    setFormData({
      name: '',
      phone: '',
      email: '',
      message: '',
    });
    setErrors({});
  };

  return (
    <div className="contact-form-container" id="contact">
      <h2>Contact Me</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          {errors.name && <p className="error">{errors.name}</p>}
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          {errors.phone && <p className="error">{errors.phone}</p>}
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        <div className="form-group">
          <label>Message</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
          {errors.message && <p className="error">{errors.message}</p>}
        </div>
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default ContactForm;