import React, { useState } from 'react';
import './Portfolio.css';

// Sample images (replace with your actual images)
import photoshop1 from './assets/g1.jpg';
import photoshop2 from './assets/g3.jpg';
import photoshop3 from './assets/g4.jpg';
import illustrator1 from './assets/4.jpg';
import illustrator2 from './assets/5.jpg';
import illustrator3 from './assets/6.jpg';
import illustrator4 from './assets/7.jpg';
import illustrator5 from './assets/8.jpg';
import illustrator6 from './assets/9.jpg';
import illustrator7 from './assets/front.jpg';
import illustrator8 from './assets/back.jpg';
import ecommerce from './assets/g10.jpg';
import digital2 from './assets/seo.jpg';

const images = {
  photoshop: [photoshop1, photoshop2, photoshop3],
  illustrator: [illustrator1, illustrator2, illustrator3, illustrator4, illustrator5, illustrator6, illustrator7, illustrator8],
  ecommerce: [ecommerce],
  digital: [digital2],
};

function Portfolio() {
  const [activeTab, setActiveTab] = useState('photoshop');

  return (
    <div className="portfolio-section" id='portfolio'>
      <h2 className="portfolio-title">My Portfolio</h2>
      <div className="portfolio-container">
        {/* Tabs */}
        <div className="tabs">
          <button
            className={`tab-button ${activeTab === 'photoshop' ? 'active' : ''}`}
            onClick={() => setActiveTab('photoshop')}
          >
            Photoshop
          </button>
          <button
            className={`tab-button ${activeTab === 'illustrator' ? 'active' : ''}`}
            onClick={() => setActiveTab('illustrator')}
          >
            Illustrator
          </button>
          <button
            className={`tab-button ${activeTab === 'ecommerce' ? 'active' : ''}`}
            onClick={() => setActiveTab('ecommerce')}
          >
            ecommerce
          </button>
          <button
            className={`tab-button ${activeTab === 'digital' ? 'active' : ''}`}
            onClick={() => setActiveTab('digital')}
          >
            Digital
          </button>
        </div>

        {/* Images */}
        <div className="image-gallery">
          <div className="image-scroller">
            {images[activeTab].map((img, index) => (
              <img key={index} src={img} alt={`${activeTab} ${index + 1}`} className="portfolio-image" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
