import React, { useState, useEffect } from 'react';
import './HeroSection.css';
import heroimg from './assets/subbu-hero-img.png'

const HeroSection = () => {
  const [highlightedText, setHighlightedText] = useState('');
  const specializations = ['E-commerce Specialist', 'Digital Marketing', 'Graphic Designing'];
  let textIndex = 0;

  // Animation to change highlighted words
  useEffect(() => {
    const interval = setInterval(() => {
      setHighlightedText(specializations[textIndex]);
      textIndex = (textIndex + 1) % specializations.length;
    }, 2000); // Change every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="hero-section" id='home'>
      {/* Left Side: About Me Section */}
      <div className="left-container">
        <div className="content">
          <h1>Welcome to My Portfolio</h1>
          <p>
          Hi I’m C. Subramanian Professional<br />
            <span className="highlighted-word"> {highlightedText}</span>
          </p>
          <p>I'm a creative Graphic Designer based in India, and I'm very passionate and dedicated to my work. Solving modern problems through design. I'm a creative Graphic Designer based in India, and I'm very passionate and dedicated to my work. Solving modern problems through design.</p>
        </div>
      </div>

      {/* Right Side: Man Image and Background */}
      <div className="right-container">
        <div className="image-container">
          <img src={heroimg} alt="Transparent Man" className="transparent-image" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
